import { Popup } from "@urbica/react-map-gl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy } from "@fortawesome/pro-solid-svg-icons"
import copy from "copy-to-clipboard"

import { Toast } from "../../../components/Toast"
import { PopupCoordinatesTypes } from "./types"

interface CoordinatesPopupProps {
  show: boolean
  popupCoordinates: PopupCoordinatesTypes
}

const CoordinatesPopup = ({
  show,
  popupCoordinates,
}: CoordinatesPopupProps) => {
  const copyCoordinates = () => {
    const coordinates = `${popupCoordinates.lat}, ${popupCoordinates.lng}`

    try {
      copy(coordinates)
      Toast.success(`Coordinates copied to clipboard: ${coordinates}`)
    } catch (error) {
      Toast.error("Failed to copy coordinates:")
    }
  }

  if (!show || popupCoordinates.lat === null) {
    return null
  }

  return (
    <Popup
      latitude={popupCoordinates.lat}
      longitude={popupCoordinates.lng as number}
      closeButton={false}
      closeOnClick={false}
    >
      <p className="flex flex-col mb-2">
        <span>Latitude: {popupCoordinates.lat}</span>
        <span>Longitude: {popupCoordinates.lng}</span>
      </p>

      <button onClick={copyCoordinates} className="link">
        <FontAwesomeIcon icon={faCopy} className="fa-regular fa-copy" /> Copy
        Coordinates
      </button>
    </Popup>
  )
}

export default CoordinatesPopup
